import React, {useContext, useEffect, useState} from 'react';
import Picker from 'react-mobile-picker';
import {Form, Modal} from 'react-bootstrap';
import styles from './completeProfile.module.css';
import sendVerificationEmail from '../../../../../api/profile/sendVerificationEmail';
import {useLang} from '../../../../i18n/OpenSooqi18n';
import editProfileApi from '../../../../../api/profile/editProfile.api';
import {useIntl} from 'react-intl';
import {AuthContext} from '../../../../i18n/AuthProvider';
import {toastify} from '../../../../../tostify/snackbarAlert';
import {showSuccess} from '../../../../helper/SystemAlert';
import IconLeftArrowHead from '../../../../icons/IconLeftArrowHead';
import IconRightArrowHead from '../../../../icons/IconRightArrowHead';
import IconClose from '../../../../icons/IconClose';
import {getCookies} from '../../../../helpers/commonFunctions';

interface ProfileCompletionModalProps {
  show?: boolean;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const monthNames = [
  {en: 'January', ar: 'يناير', id: 1},
  {en: 'February', ar: 'فبراير', id: 2},
  {en: 'March', ar: 'مارس', id: 3},
  {en: 'April', ar: 'أبريل', id: 4},
  {en: 'May', ar: 'مايو', id: 5},
  {en: 'June', ar: 'يونيو', id: 6},
  {en: 'July', ar: 'يوليو', id: 7},
  {en: 'August', ar: 'أغسطس', id: 8},
  {en: 'September', ar: 'سبتمبر', id: 9},
  {en: 'October', ar: 'أكتوبر', id: 10},
  {en: 'November', ar: 'نوفمبر', id: 11},
  {en: 'December', ar: 'ديسمبر', id: 12},
];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const currentDay = new Date().getDate();

const FullScreenProfileCompletionModal: React.FC<ProfileCompletionModalProps> = ({
  show,
  setShowModal,
}) => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [showGenderModal, setShowGenderModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState({email: '', birthday: ''});
  const {userIdentity} = useContext(AuthContext);
  const [activeButton, setActiveButton] = useState(null);
  const intl = useIntl();
  const lang = useLang();

  const [formData, setFormData] = useState({
    M_email: '',
    gender: '',
    dateOfBirth: '',
    accountType: '',
  });

  const getUpdatedUserProfileCookie = getCookies().saveUserProfileData;
  const [popupCount, setPopupCount] = useState(0);
  const yearValidationValue = userIdentity?.complete_profile?.year_validation;
  const minimumYear = currentYear - yearValidationValue;

  const handleClick = (index) => {
    setActiveButton(index);
  };

  useEffect(() => {
    const popupData = JSON.parse(getCookies().popupAppearanceDays || '[]');

    if (popupData.length > 0 && userIdentity?.id) {
      const userCounts = popupData.filter((item) => item.userId === userIdentity?.id);

      if (userCounts.length > 0) {
        const totalCount = userCounts.reduce((sum, item) => sum + (item.count || 0), 0);
        setPopupCount(totalCount);
      } else {
        setPopupCount(0);
      }
    } else {
      setPopupCount(0);
    }
  }, [userIdentity]);

  // To handle the birthday on mobile
  const defaultYear = currentYear - yearValidationValue;
  const defaultMonthValue = new Date().getMonth() + 1;
  const defaultMonthName = monthNames.find((month) => month.id === defaultMonthValue)?.[lang];
  const defaultDay = new Date().getDate();

  const dateOfBirth = formData?.dateOfBirth ? new Date(formData.dateOfBirth) : null;
  const defaultMonthIndex = monthNames.findIndex(
    (month) => month[lang].toLowerCase() === defaultMonthName.toLowerCase()
  );

  const [pickerValue, setPickerValue] = useState({
    year: String(dateOfBirth ? dateOfBirth.getFullYear() : defaultYear),
    month: dateOfBirth
      ? monthNames[dateOfBirth.getMonth()][lang]
      : monthNames[defaultMonthIndex][lang],
    day: String(
      dateOfBirth
        ? dateOfBirth.getDate().toString().padStart(2, '0')
        : defaultDay.toString().padStart(2, '0')
    ),
  });

  const getDaysInMonth = (month, year) => {
    const monthIndex = monthNames.findIndex((m) => (lang === 'ar' ? m.ar : m.en) === month);
    const monthNumber = monthIndex + 1;
    return new Date(Number(year), monthNumber, 0).getDate();
  };

  const calculateDays = (month, year) => {
    const daysInMonth = getDaysInMonth(month, year);
    return Array.from({length: daysInMonth}, (_, r) => ({
      value: (r + 1).toString().padStart(2, '0'),
      disabled: false,
    }));
  };

  const [selections, setSelections] = useState({
    month: [],
    day: [],
    year: [],
  });

  useEffect(() => {
    const updatedSelections = {
      month: monthNames.map((month) => ({
        value: lang === 'ar' ? month.ar : month.en,
        disabled: false,
      })),
      year: Array.from({length: currentYear - 1900 + 1}, (_, i) => {
        const year = 1900 + i;
        return {
          value: year.toString(),
          disabled: year > minimumYear,
        };
      }),
      day: calculateDays(pickerValue.month, pickerValue.year),
    };

    updatedSelections.month.forEach((month, index) => {
      if (
        parseInt(pickerValue.year) > minimumYear ||
        (parseInt(pickerValue.year) === minimumYear && index + 1 > currentMonth)
      ) {
        month.disabled = true;
      }
    });

    updatedSelections.day = updatedSelections.day.map((day) => {
      const pickedMonthId = monthNames.find(
        (month) => pickerValue.month === month[lang] || Number(pickerValue.month) === month.id
      );
      if (
        parseInt(pickerValue.year) > minimumYear ||
        (parseInt(pickerValue.year) === minimumYear && pickedMonthId?.id >= currentMonth)
      ) {
        if (
          parseInt(pickerValue.year) > minimumYear ||
          (parseInt(pickerValue.year) === minimumYear && pickedMonthId.id > currentMonth) ||
          parseInt(day.value) > currentDay
        ) {
          day.disabled = true;
        }
      }
      return day;
    });

    setSelections(updatedSelections);
  }, [pickerValue]);

  // Handle save button in mobile date picker
  const isAnyValueDisabledInDatePicker = Object.keys(pickerValue).some((type) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();
    const minimumYear = currentYear - yearValidationValue;

    const monthIndex =
      monthNames.findIndex(
        (month) => month[lang] === pickerValue.month || month.id === Number(pickerValue.month)
      ) + 1;
    const isYearDisabled = parseInt(pickerValue.year) > minimumYear;
    const isMonthDisabled = parseInt(pickerValue.year) === minimumYear && monthIndex > currentMonth;
    const isDayDisabled =
      parseInt(pickerValue.year) === minimumYear &&
      monthIndex === currentMonth &&
      parseInt(pickerValue.day) > currentDay;

    return isYearDisabled || isMonthDisabled || isDayDisabled;
  });

  const handleDateChange = (value) => {
    setPickerValue(value);
  };

  const handleSaveDateValue = () => {
    const monthIndex =
      monthNames.findIndex(
        (month) => month[lang] === pickerValue.month || month.id === Number(pickerValue.month)
      ) + 1;

    const formattedMonth = monthIndex.toString().padStart(2, '0');
    const formattedDay = pickerValue.day.toString().padStart(2, '0');

    const selectedDate = `${pickerValue.year}-${formattedMonth}-${formattedDay}`;
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: selectedDate,
    }));
    setShowDateModal(false);
  };

  const handleOpenPickerModal = () => {
    setPickerValue({
      year: String(dateOfBirth ? dateOfBirth.getFullYear() : defaultYear),
      month: dateOfBirth
        ? monthNames[dateOfBirth.getMonth()][lang]
        : monthNames[defaultMonthIndex][lang],
      day: String(
        dateOfBirth
          ? dateOfBirth.getDate().toString().padStart(2, '0')
          : defaultDay.toString().padStart(2, '0')
      ),
    });

    setShowDateModal(true);
  };

  const handleGenderSelect = (value: string) => {
    const genderValue = value === 'Male' ? '1' : '2';
    setFormData((prevData) => ({
      ...prevData,
      gender: genderValue,
    }));
    setShowGenderModal(false);
  };

  const verifyEmail = (event) => {
    event.preventDefault();
    const data = {email: formData.M_email};
    const errorMessage = intl.formatMessage({id: 'myProfile.profileDetails.text49'});
    const enterEmailMessage = intl.formatMessage({
      id: 'myProfile.profileDetails.pleaseEnterTheEmail',
    });
    const successMessage = intl.formatMessage({id: 'myProfile.profileDetails.text48'});
    sendVerificationEmail(data)
      .then((response) => {
        if (response.status === 200) {
          toastify({severity: 'success', title: successMessage, locale: lang});
          setErrorMessages((prevData) => ({
            ...prevData,
            email: '',
          }));
        } else {
        }
      })
      .catch((errorResponse) => {
        const errorData = errorResponse.response?.data;

        const emailErrorMessage =
          Array.isArray(errorData) && errorData?.[0]?.message
            ? errorData?.[0].message
            : errorData?.message
            ? errorData?.message
            : formData?.M_email.length > 0
            ? errorMessage
            : enterEmailMessage;

        setErrorMessages((prevData) => ({
          ...prevData,
          email: emailErrorMessage,
        }));
      });
  };

  const saveChanges = (event) => {
    event.preventDefault();
    const data = {
      Member: {
        M_email: formData.M_email || '',
        gender: formData.gender || '',
        birthday: formData.dateOfBirth || '',
        account_type: formData.accountType || '',
      },
    };

    const errorMessage = intl.formatMessage({id: 'myProfile.profileDetails.text49'});

    editProfileApi(data)
      .then(() => {
        setErrorMessages((prevData) => ({
          ...prevData,
          birthday: '',
        }));
        setShowModal(false);
        showSuccess(intl, lang);
      })
      .catch((error) => {
        if (error?.response?.data) {
          error.response.data.forEach((err) => {
            if (err.field === 'birthday') {
              setErrorMessages((prevData) => ({
                ...prevData,
                birthday: err.message,
              }));
            }

            if (err.field === 'M_email') {
              const emailErrorMessage =
                Array.isArray(error?.response?.data) && error?.response?.data?.[0]?.message
                  ? error?.response?.data?.[0].message
                  : error?.response?.data?.message
                  ? error?.response?.data?.message
                  : errorMessage;

              setErrorMessages((prevData) => ({
                ...prevData,
                email: emailErrorMessage,
              }));
            }
          });
        }
      });
  };

  useEffect(() => {
    if (showGenderModal || showDateModal) {
      setTimeout(() => {
        const modalDialogs = document.querySelectorAll(
          '.fade.modal.show'
        ) as NodeListOf<HTMLElement>;
        const modalDialogsContent = document.querySelectorAll(
          '.modal-content'
        ) as NodeListOf<HTMLElement>;
        if (modalDialogs.length > 0) {
          modalDialogs.forEach((modal) => {
            modal.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
          });
        }

        if (modalDialogsContent?.length > 0) {
          modalDialogsContent.forEach((modalContent) => {
            modalContent.style.borderTopLeftRadius = '28px';
            modalContent.style.borderTopRightRadius = '28px';
          });
        }
      }, 0);
    }
  }, [showGenderModal, showDateModal]);

  const isPopupThresholdMet = popupCount >= userIdentity?.complete_profile?.required_after;
  const isFormIncomplete =
    (!userIdentity?.complete_profile?.date_of_birth_filled && formData.dateOfBirth === '') ||
    (!userIdentity?.complete_profile?.gender_filled && formData.gender === '') ||
    (!userIdentity?.complete_profile?.email_filled && formData.M_email === '') ||
    (!userIdentity?.complete_profile?.account_type_filled && formData.accountType === '');
  const shouldTriggerPopup = isPopupThresholdMet && isFormIncomplete;

  const notOfAnyFilesFilled =
    formData.dateOfBirth === '' &&
    formData.gender === '' &&
    formData.M_email === '' &&
    formData.accountType === '';

  return (
    <>
      <Modal show={show} onHide={() => setShowModal(false)} dialogClassName='modal-fullscreen'>
        <Modal.Header>
          <div className='d-flex flex-column w-100'>
            {popupCount < userIdentity?.complete_profile?.required_after && (
              <IconClose
                fill='#000'
                height='23'
                width='23'
                className='mb-6'
                onClick={() => {
                  if (getUpdatedUserProfileCookie) {
                    document.cookie =
                      'saveUserProfileData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                  }
                  setShowModal(false);
                }}
              />
            )}
            <strong className={`${styles.closeIcon}`}>
              {intl.formatMessage({id: 'myProfile.profileDetails.completeYourProfile'})}
            </strong>
            <div className={`mt-2 text-muted ${styles.subTitle}`}>
              {intl.formatMessage({id: 'myProfile.profileDetails.provideYourInfo'})}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className='d-flex flex-column bg-light'>
          <Form className='w-100 p-4 border bg-white rounded'>
            {!userIdentity?.complete_profile?.email_filled && (
              <Form.Group controlId='formEmail' className='mb-5'>
                <label className='fw-bold mb-3 fs-4'>
                  {intl.formatMessage({id: 'myProfile.profileDetails.text4'})}
                </label>
                <div className='input-group border rounded'>
                  <Form.Control
                    type='text'
                    placeholder={intl.formatMessage({
                      id: 'myProfile.profileDetails.enterYourEmail',
                    })}
                    name='email'
                    value={formData.M_email}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        M_email: e.target.value.trim(),
                      })
                    }
                    style={{color: 'black', textAlign: lang === 'ar' ? 'right' : 'left'}}
                    className='p-5 border-0'
                  />
                  <button
                    className='me-1 border-0 bg-white fs-5 fw-bold noRipple'
                    style={{color: '#808080'}}
                    onClick={(event) => verifyEmail(event)}
                  >
                    {intl.formatMessage({id: 'myProfile.profileDetails.text46'})}
                  </button>
                </div>
                {errorMessages.email && (
                  <div className='fv-plugins-message-container invalid-feedback fs-6 ms-1'>
                    {errorMessages.email}
                  </div>
                )}
              </Form.Group>
            )}

            {!userIdentity?.complete_profile?.gender_filled && (
              <Form.Group controlId='formGender' className='mt-3 mb-8 position-relative'>
                <label className='fw-bold mb-3 fs-4'>
                  {intl.formatMessage({id: 'myProfile.profileDetails.text39'})}
                </label>
                <Form.Control
                  type='text'
                  placeholder={intl.formatMessage({id: 'myProfile.profileDetails.selectGender'})}
                  name='gender'
                  value={
                    formData.gender === '1'
                      ? intl.formatMessage({id: 'myProfile.profileDetails.text41'})
                      : formData.gender === '2'
                      ? intl.formatMessage({id: 'myProfile.profileDetails.text42'})
                      : ''
                  }
                  onClick={() => setShowGenderModal(true)}
                  readOnly
                  className='p-5 fs-5'
                />
                <i
                  className={`fas fa-angle-down fw-bold fs-4 position-absolute ${styles.iconStyle}`}
                  style={{left: lang === 'ar' ? '10px' : '295px'}}
                ></i>
              </Form.Group>
            )}
            {!userIdentity?.complete_profile?.date_of_birth_filled && (
              <Form.Group controlId='formBirthday' className='mt-3 mb-8'>
                <label className='fw-bold mb-3 fs-4'>
                  {intl.formatMessage({id: 'myProfile.profileDetails.text43'})}
                </label>
                <Form.Control
                  type='text'
                  placeholder={intl.formatMessage({
                    id: 'myProfile.profileDetails.selectDateOfBirth',
                  })}
                  value={`${formData.dateOfBirth}`}
                  readOnly
                  onClick={handleOpenPickerModal}
                  className='p-5'
                />
                {errorMessages.birthday && (
                  <div className='fv-plugins-message-container invalid-feedback mt-3 fs-6'>
                    {errorMessages.birthday}
                  </div>
                )}
              </Form.Group>
            )}
            {!userIdentity?.complete_profile?.account_type_filled && (
              <div className='mb-9'>
                <label className={`${styles.labelSize} text-start fw-bold`}>
                  {intl.formatMessage({id: 'accountType'})}
                </label>

                <div
                  className={`${
                    styles.fakeRadioButtonWrapper
                  } d-flex gap-3 mb-8 border rounded-2 p-3 text-body hoverPointer ${
                    activeButton === 1 ? styles.active : ''
                  }`}
                  onClick={() => {
                    handleClick(1);
                    setFormData({...formData, accountType: '1'});
                  }}
                >
                  <div>
                    <div
                      className={`${styles.fakeRadioButton} position-relative rounded-circle ${styles.circleBorder} bg-white`}
                    >
                      <span
                        className={`rounded-circle d-block ${
                          activeButton === 1 ? 'bg-primary' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <div>
                    <strong className={`${styles.fontSizeAccountType}`}>
                      {intl.formatMessage({id: 'accountTypeTitle_1'})}
                    </strong>
                    <p className={`${styles.fontSizeAccountTypeSubTitle} mt-2`}>
                      {intl.formatMessage({id: 'accountType_p1'})}
                    </p>
                  </div>
                </div>

                <div
                  className={`${
                    styles.fakeRadioButtonWrapper
                  } d-flex gap-3 border rounded-2 p-3 text-body hoverPointer ${
                    activeButton === 2 ? styles.active : ''
                  }`}
                  onClick={() => {
                    handleClick(2);
                    setFormData({...formData, accountType: '2'});
                  }}
                >
                  <div>
                    <div
                      className={`${styles.fakeRadioButton} position-relative rounded-circle ${styles.circleBorder} bg-white`}
                    >
                      <span
                        className={`rounded-circle d-block ${
                          activeButton === 2 ? 'bg-primary' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <div>
                    <strong className={`${styles.fontSizeAccountType}`}>
                      {intl.formatMessage({id: 'accountTypeTitle_2'})}
                    </strong>
                    <p className={`${styles.fontSizeAccountTypeSubTitle} mt-2`}>
                      {intl.formatMessage({id: 'accountType_p2'})}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer className='d-flex justify-content-center'>
          <button
            onClick={(event) => saveChanges(event)}
            className={`fw-bold rounded p-4 width-100 ${styles.saveFont} ${
              notOfAnyFilesFilled || shouldTriggerPopup
                ? `${styles.btnGray}`
                : styles.saveButtonMobile
            }`}
          >
            {intl.formatMessage({id: 'mylistings.filter.rangesave'})}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Gender Selection Modal */}
      <Modal
        show={showGenderModal}
        onHide={() => setShowGenderModal(false)}
        dialogClassName={styles.customModalDialog}
      >
        <span
          className={`shadow-sm ms-5 mt-8 rounded-circle d-flex align-items-center justify-content-center mb-4 ${styles.closeButton}`}
        >
          <IconClose fill='#000' height='22' width='22' onClick={() => setShowGenderModal(false)} />
        </span>
        <h1 className={`p-2 ms-3 text-start ${styles.titleFont}`}>
          {intl.formatMessage({id: 'myProfile.profileDetails.selectGender'})}
        </h1>
        <Modal.Body>
          <div className='gender-list'>
            <div
              className='d-flex justify-content-between align-items-center gender-item p-2 mb-2 border-bottom'
              onClick={() => handleGenderSelect('Male')}
            >
              <span className={styles.genderFont}>
                {intl.formatMessage({id: 'myProfile.profileDetails.text41'})}
              </span>
              {lang === 'ar' ? (
                <IconLeftArrowHead fill='#000' />
              ) : (
                <IconRightArrowHead fill='#000' />
              )}
            </div>
            <div
              className='d-flex justify-content-between align-items-center gender-item p-2 mb-2'
              onClick={() => handleGenderSelect('Female')}
            >
              <span className={styles.genderFont}>
                {intl.formatMessage({id: 'myProfile.profileDetails.text42'})}
              </span>
              {lang === 'ar' ? (
                <IconLeftArrowHead fill='#000' />
              ) : (
                <IconRightArrowHead fill='#000' />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Date Picker Modal */}
      <Modal
        show={showDateModal}
        onHide={() => setShowDateModal(false)}
        dialogClassName={styles.customModalDialog}
      >
        <span
          className={`shadow-sm ms-5 mt-8 rounded-circle d-flex align-items-center justify-content-center mb-4 ${styles.closeButton}`}
        >
          <IconClose fill='#000' height='22' width='22' onClick={() => setShowDateModal(false)} />
        </span>
        <h2 className={`p-2 ms-4 ${styles.titleFont} text-start`}>
          {intl.formatMessage({id: 'myProfile.profileDetails.selectDateOfBirth'})}
        </h2>
        <Modal.Body>
          <Picker value={pickerValue} onChange={handleDateChange}>
            {['month', 'day', 'year'].map((type) => (
              <Picker.Column name={type} key={type}>
                {selections[type].map((item) => {
                  const value = item.value;
                  const isDisabled = item.disabled;

                  return (
                    <Picker.Item
                      key={value}
                      value={value}
                      disabled={isDisabled}
                      style={{
                        backgroundColor: isDisabled
                          ? 'transparent'
                          : pickerValue[type] === value
                          ? '#f5f8fa'
                          : 'white',
                        borderRadius: '4px',
                        opacity: isDisabled ? 0.5 : 1,
                        fontSize: '15.5px',
                        fontWeight: 'bold',
                      }}
                    >
                      {value}
                    </Picker.Item>
                  );
                })}
              </Picker.Column>
            ))}
          </Picker>
        </Modal.Body>
        <button
          onClick={handleSaveDateValue}
          className={`fw-bold justify-content-center ${styles.saveButtonMobile} ${
            styles.saveFont
          } rounded p-4 ${isAnyValueDisabledInDatePicker ? styles.btnGray : ''}`}
          style={{width: '88%'}}
          disabled={isAnyValueDisabledInDatePicker}
        >
          {intl.formatMessage({id: 'mylistings.filter.rangesave'})}
        </button>
      </Modal>
    </>
  );
};

export default FullScreenProfileCompletionModal;
