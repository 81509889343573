import React, {useEffect, useMemo, useState} from 'react'
import {AddFieldType, RemoveFieldType} from '../../../interfaces/MyListings'
import {useLang} from '../../../i18n/OpenSooqi18n'
import {useIntl} from 'react-intl'
import useFiltersStore from '../my-listings/FilterStore'
import { useLocation } from 'react-router-dom'

const StatusesMultiSelect = ({
  fields,
  addField,
  removeField,
  selectedFields,
  isPillAllEnabled,
  togglePillAllHandler,
  counts,
}: {
  fields: any
  addField: AddFieldType
  removeField: RemoveFieldType
  selectedFields: any
  isPillAllEnabled: boolean
  togglePillAllHandler: () => void
  counts: any
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])
  const lang = useLang()
  const intl = useIntl()
  const {globalFiltersDisable} = useFiltersStore()
  const location = useLocation()

  // const selectedOptions = useMemo(() => {
  //   const selected = selectedFields[item.fieldId];
  //   if (Array.isArray(selected)) {
  //     return options.filter(option => selected.some(selectedOp => selectedOp.value === option.value));
  //   }
  //   return [];
  // }, [selectedFields, options, item.fieldId]);

  //   useEffect(() => {
  //     if (selectedFields[item.fieldId]) {
  //       const selected: any = Array.isArray(selectedFields[item.fieldId])
  //         ? selectedFields[item.fieldId]
  //         : [selectedFields[item.fieldId]] || []
  //       setSelectedOptions(selected.map((option) => ({value: option.value, label: option.label})))
  //     }
  //   }, [])


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
  
    fields.items.forEach((item) => {
      if (searchParams.has(item.fieldId)) {
        handleSelectOption(item);
      }
    });
  }, []);

  const handleSelectOption = (field) => {
    if (field.value === 0) {
      // setIsToggled((old) => !old)
      for (const option of fields.items) {
        if (selectedFields[option.fieldId]) {
          removeField(option.fieldId, {value: option.value}, 'SINGLE')
          setSelectedOptions((oldOptions) =>
            oldOptions.filter((option) => option.fieldId !== option.fieldId)
          )
        }
      }
      togglePillAllHandler()
      return
    }
    if (isPillAllEnabled) {
      togglePillAllHandler()
    }
    const isOptionSelected = selectedFields[field.fieldId]
    if (isOptionSelected) {
      removeField(field.fieldId, {value: field.value}, 'SINGLE')
      setSelectedOptions((oldOptions) =>
        oldOptions.filter((option) => option.fieldId !== field.fieldId)
      )
    } else {
      addField(field?.fieldId, {value: field?.value, label: field?.label}, 'SINGLE', false)
      setSelectedOptions((oldOptions) => [
        ...oldOptions,
        {value: field.value, fieldId: field.fieldId, label: field.label},
      ])
    }
  }

  //   useEffect(() => {
  //     if (!isEnabled) {
  //       selectedOptions.forEach((option) =>
  //         removeField(item.fieldId.toString(), {value: option.value}, 'MULTI')
  //       )
  //     }
  //   }, [isEnabled, selectedOptions, item.fieldId, removeField])

  const showModalHandler = () => {
    setIsModalOpen(true)
  }

  return (
    <div className={`col-4`} style={{borderRadius: '0.75rem'}} onClick={()=>{if (!globalFiltersDisable)showModalHandler()}}>
      <div
        className='card w-100 d-flex align-items-center justify-content-between flex-row p-2 px-3'
      >
        <div className='d-flex flex-column py-3 w-75'>
          <div className={`text-16 text-truncate w-100 ${globalFiltersDisable ? 'text-muted' : ''}`}>
            {selectedOptions.length > 0
              ? selectedOptions.map((option) => option.label).join(', ')
              : fields.label}
          </div>
        </div>
        <i className='fa-solid fa-chevron-down w-25'></i>{' '}
      </div>
      {isModalOpen && (
        <div className='modal show d-block' style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <div className='modal-dialog modal-fullscreen'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title fs-1'>{fields.label}</h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsModalOpen(false)
                  }}
                ></button>
              </div>
              <div className='modal-body d-flex flex-column gap-2'>
                {/* <div className='mb-3'> */}
                {/* {selectedOptions.length > 0 && (
                    <div
                      className='d-flex flex-row gap-2 mb-2 py-4'
                      style={{overflowX: 'auto', whiteSpace: 'nowrap'}}
                    >
                      {selectedOptions.map((option) => (
                        <div
                          key={option.value}
                          className='badge p-3 fs-4 bg-dark d-flex align-items-center'
                        >
                          {option.label}
                          <button
                            type='button'
                            className='btn-close btn-close-white fs-4 ms-2'
                            onClick={() => handleSelectOption(option)}
                          ></button>
                        </div>
                      ))}
                    </div>
                  )} */}
                {/* </div> */}

                <div
                  key={'status_all'}
                  className={`card p-5 py-7 fs-2 fw-bold mb-3 shadow-sm d-flex flex-row justify-content-between align-items-center hoverPointer
                        `}
                  //         ${
                  //      selectedOptions.find((op) => op.value === option.value) ? 'selected' : ''
                  //    }
                  style={{borderRadius: '0.5rem'}}
                  onClick={() =>
                    handleSelectOption({
                      fieldId: 'status_all',
                      label: intl.formatMessage({id: 'mylistings.pills.all'}),
                      value: 0,
                    })
                  }
                >
                  <span>
                    {intl.formatMessage({id: 'mylistings.pills.all'})} ({counts['all'] ?? 0})
                  </span>
                  <i
                    className={`fs-1  ${
                      isPillAllEnabled ? 'fas fa-check-square text-primary' : 'far fa-square text-muted'
                    }`}
                  ></i>
                </div>

                {fields?.items.map((field, index) => (
                  <div
                    key={field.fieldId + ',' + index}
                    className={`card p-5 py-7 fs-2 fw-bold mb-3 shadow-sm d-flex flex-row justify-content-between align-items-center hoverPointer
                        `}
                    //         ${
                    //      selectedOptions.find((op) => op.value === option.value) ? 'selected' : ''
                    //    }
                    style={{borderRadius: '0.5rem'}}
                    onClick={() => handleSelectOption(field)}
                  >
                    <span>
                      {field?.label} ({counts[field?.fieldId] ?? 0})
                    </span>
                    <i
                      className={`fs-1 ${
                        selectedOptions.find((op) => op.fieldId === field.fieldId)
                          ? 'fas fa-check-square text-primary'
                          : 'far fa-square text-muted'
                      }`}
                    ></i>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default StatusesMultiSelect
