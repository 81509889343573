import { generateImagePath } from "../../helper/imagePathHelper"
import { KTSVG } from "../../helpers"
import { deepLinkHandler } from "../../helpers/commonFunctions"
import { isMobile } from 'react-device-detect';

interface Props {
  title: {[key: string]: any}
  description: {[key: string]: any}
  icon: string
  button: {[key: string]: any}
}

export function ShareReviews({title, description, icon, button}: Props) {
  
  return (
    <div
      id='share_review'
      className='d-flex flex-column bg-light-primary rounded border-primary border border-dashed p-5'
    >
      <div className='d-flex align-items-start'>
        {icon && <img width='50px' height='50px' src={generateImagePath(icon, 'xxh')} alt='Review Icon' className='me-3' />}
        <div style={{wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>
        <h4 style={{color: '#000000'}} className='fw-bolder '>
            {title?.text} {title?.icon &&  <img width='25px' height='25px' src={generateImagePath(title?.icon, 'xxh')} alt='title Icon' />
          }
          </h4>
          <div className='fs-8 text-gray-700 pe-7 mb-3'>{description?.text}</div>
        </div>
      </div>

      <div style={{width: '18.5rem'}} className='d-flex align-items-center justify-content-center'>
        <button
          style={{width: '58%'}}
          className='btn px-6 mt-4 btn-primary align-self-center text-nowrap fw-bolder '
          onClick={() => {
            isMobile ? deepLinkHandler(button?.deeplink, '_self'): deepLinkHandler(button?.deeplink)
          }}
        >
          {button?.title}
        </button>
      </div>
    </div>
  )
}
