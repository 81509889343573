import React, {useEffect, useState} from 'react'
import {AddFieldType, ListingPillI, RemoveFieldType} from '../../../../interfaces/MyListings'
import Skeleton from 'react-loading-skeleton'
import useFiltersStore from '../../my-listings/FilterStore'
import { useLocation } from 'react-router-dom'

interface ListingPillProps {
  pill: ListingPillI
  addField: AddFieldType
  removeField: RemoveFieldType
  isAllEnabled: boolean
  count: number
  togglePillAllHandler?: () => void
  reset: boolean
}

const ListingPill: React.FC<ListingPillProps> = ({
  pill,
  addField,
  removeField,
  count,
  isAllEnabled,
  togglePillAllHandler,
  reset,
}) => {
  const {label, fieldId, value} = pill
  const [isToggled, setIsToggled] = useState(isAllEnabled && value === 0 ? true : false)
  const {globalFiltersDisable} = useFiltersStore()
  const location = useLocation()

  useEffect(() => {
    // if (reset) setIsToggled(false)
  }, [reset])


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const pillFieldUrl = searchParams.get(pill.fieldId)
    if (pillFieldUrl) {
      toggleTrigger()
    }
  }, [])

  useEffect(() => {

    if (isToggled && isAllEnabled && value !== 0) {

      removeField(fieldId, {value: value}, 'SINGLE')
      setIsToggled(false)
    }
    if (!isAllEnabled && isToggled && value === 0) {

      setIsToggled(false)
    }
  }, [isAllEnabled, isToggled])

  useEffect(() => {
    if (value === 0 && isAllEnabled) setIsToggled(true)
  }, [isAllEnabled])

  const toggleTrigger = () => {
    if (value === 0) {
      setIsToggled((old) => !old)
      togglePillAllHandler()
      return
    }
    if (isAllEnabled) {
      togglePillAllHandler()
    }
    setIsToggled((old) => {
      if (old) removeField(pill.fieldId, {value: value}, 'SINGLE')
      else addField(pill.fieldId, {value: value, label: label}, 'SINGLE', false)
      return !old
    })
    
  }

  const isCountValid = typeof count === 'number'
  return (
    <div
      key={`listing_pill_${fieldId}`}
      data-id={fieldId}
      style={{borderRadius: '10px'}}
      className={`hoverPointer user-select-none 
        ${
        isToggled ? 'bg-primary fw-bold text-white text-19' : 'bg-light fw-normal text-dark text-20'
      } 
      fs-3 px-4 pt-3 pb-2 d-flex flex-column align-items-center ${
        globalFiltersDisable ? 'hoverNotAllowed' : ''
      }`}
      onClick={() => {
        if (!globalFiltersDisable) toggleTrigger()
      }}
    >
      <span className={`text-align-center  ${isToggled ? '' : 'text-muted'}`}>
        {label}
        {isCountValid && <span className={`fw-normal mx-1`}>({count ?? 0})</span>}
        {!isCountValid && (
          <span className='mx-2'>
            <Skeleton width={15} height={15} />
          </span>
        )}
      </span>
    </div>
  )
}

export default ListingPill
