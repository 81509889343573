import {Suspense, useContext, useEffect, useMemo, useState} from 'react'
import {Outlet} from 'react-router-dom'
import Style from './assets/js/Style'
import {I18nProvider} from '../app/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../app/layout/core'
import {MasterInit} from '../app/layout/MasterInit'
import {NotificationProvider} from 'opensooq-notification'
import {useLang} from '../app/i18n/OpenSooqi18n'
import {ChatProvider} from 'opensooq-chat'
import {isMobile} from 'react-device-detect'
import {
  clearSharedTokenPromise,
  generateOpenSooqRedirectUrl,
  generateToken,
  getCookies,
  showChatInCountry,
} from './helpers/commonFunctions'
import {showError} from './helper/SystemAlert'
import {useIntl} from 'react-intl'
import * as Sentry from '@sentry/react'
import 'opensooq-chat/dist/esm/css'
import AuthProvider, {AuthContext} from './i18n/AuthProvider'
import {useLogging} from '../logging/hooks/useLogging'
import {RTEventResponseType} from './interfaces/RealTimeModal'
import RealTimeModal from './modales/RealTimeModal'
import getProfileApi from '../api/profile/getProfile.api'
import useAccountOwnerStore from './layout/components/sidebar/sidebar-menu/AccountOwnerStore'

const App = () => {
  console.log('v.1.0.188')
  const [realTimeModal, setRealTimeModal] = useState(false)
  const [data, setData] = useState<RTEventResponseType>()
  const {setAccountOwner} = useAccountOwnerStore()
  useLogging()

  const FetchProfile = async () => {
    try {
      const response = await getProfileApi()
      setAccountOwner(response.data.member_owner)
    } catch (error) {}
  }

  useEffect(() => {
    clearSharedTokenPromise()
    FetchProfile()
    if (isMobile) {
      document.body.classList.add('mobileSite')
    } else {
      document.body.classList.add('desktopSite')
    }
  }, [])

  const RTHandler = (data: RTEventResponseType) => {
    setData(data)
    setRealTimeModal(true)
  }

  if (process.env.REACT_APP_SENTRY_URL && process.env.REACT_APP_SENTRY_URL !== '') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Style>
        <AuthProvider>
          <I18nProvider>
            <LayoutProvider>
              {showChatInCountry() && (
                <Bundles RTHandler={RTHandler}>
                  <Outlet />
                  <MasterInit />
                </Bundles>
              )}
              {!showChatInCountry() && (
                <BundlesNoChat RTHandler={RTHandler}>
                  <Outlet />
                  <MasterInit />
                </BundlesNoChat>
              )}
              <RealTimeModal
                RTResponse={data}
                show={realTimeModal}
                setRealTimeModal={setRealTimeModal}
              />
            </LayoutProvider>
          </I18nProvider>
        </AuthProvider>
      </Style>
    </Suspense>
  )
}

const Bundles = ({
  children,
  RTHandler,
}: {
  children: any
  RTHandler: (data: RTEventResponseType) => void
}) => {
  const cookies = getCookies()
  const lang = useLang()
  const Intl = useIntl()
  const {isLoggedIn, regenerateToken, userIdentity} = useContext(AuthContext)
  let authenticationData = {
    userInfo: cookies.userInfo,
    source: cookies.source,
    audience: cookies.audience,
    country: cookies.ecountry,
    shopName: '',
  }
  const postViewUri = `${generateOpenSooqRedirectUrl(
    process.env.REACT_APP_OPENSOOQ_BASE,
    cookies.ecountry,
    lang
  )}/search/{post_id}`
  const chatLoggingConfig = {
    loggingEnv: {
      url: process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT,
      token: '',
    },
    version: process.env.REACT_APP_PUBLIC_VERSION,
  }
  const BundlesMemoed = useMemo(() => {
    if (userIdentity && userIdentity.shopName) {
      authenticationData.userInfo.shopName = userIdentity.shopName
    }
    return (
      <ChatProvider
        lang={lang}
        authenticationData={authenticationData}
        publicUrl={window.location.origin}
        urls={{
          arachnaApi: process.env.REACT_APP_OPENSOOQ_API_URL_BASE_V2 ?? '',
          arbok: process.env.REACT_APP_ARBOK_URL_BASE ?? '',
          chat: process.env.REACT_APP_CHAT_URL ?? '',
          postView: postViewUri,
          nodeApi: process.env.REACT_APP_API_SUB_URL,
        }}
        actions={{
          onCallFailed: () => {
            showError(Intl, lang, {message: Intl.formatMessage({id: 'message.call-failed'})})
          },
          getToken: generateToken,
        }}
        loggingChatConfig={chatLoggingConfig}
      >
        <NotificationProvider
          RTHandler={RTHandler}
          authenticationData={authenticationData}
          urls={{gulpin2: process.env.REACT_APP_GULPIN_2_URL ?? ''}}
          lang={lang}
          publicUrl={window.location.origin}
          getToken={regenerateToken}
        >
          {children}
        </NotificationProvider>
      </ChatProvider>
    )
  }, [isLoggedIn, userIdentity])
  return BundlesMemoed
}

const BundlesNoChat = ({
  children,
  RTHandler,
}: {
  children: any
  RTHandler: (data: RTEventResponseType) => void
}) => {
  const cookies = getCookies()
  const lang = useLang()
  const authenticationData = {
    userInfo: cookies.userInfo,
    source: cookies.source,
    audience: cookies.audience,
    country: cookies.ecountry,
  }
  const {isLoggedIn, regenerateToken} = useContext(AuthContext)
  const BundlesMemoed = useMemo(() => {
    return (
      <NotificationProvider
        RTHandler={RTHandler}
        authenticationData={authenticationData}
        urls={{gulpin2: process.env.REACT_APP_GULPIN_2_URL ?? ''}}
        lang={lang}
        publicUrl={window.location.origin}
        getToken={regenerateToken}
      >
        {children}
      </NotificationProvider>
    )
  }, [isLoggedIn])
  return BundlesMemoed
}
export {App}